import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HrServicesWeOffer = () => {
  const [HrServices, setHrServices] = useState([]);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/./HrServices.json`)
      .then((response) => response.json())
      .then((data) => setHrServices(data))
      .catch((error) => console.error("Error fetching Hr services:", error));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="slider">
      <Slider {...settings}>
        {HrServices.map((Hrservice, index) => (
          <div className="va-card-container">
            <img
              src={Hrservice.icon}
              alt={`Service Icon ${index + 1}`}
              style={{ objectFit: "cover" }}
              className="hexagon-image"
            />
            <h4>{Hrservice.title}</h4>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HrServicesWeOffer;
