import React from "react";
import './AboutUs.css';
import VisionMission from "./VisionMission";

const AboutUs = () => {
  return (
    <div className="about-us-container">
            <div id="aboutUs"></div>

      <section className="about-header">
        <h1>About WeFrame</h1>
        <p>
          Welcome to WeFrame, your destination for comprehensive solutions in
          Virtual Assistant, HR Management, and Web Development.
        </p>
      </section>

      <section className="about-content">
        <h2>Our Story:</h2>
        <p>
          Born out of a passion for innovation and a commitment to excellence,
          WeFrame is founded with a vision to redefine how businesses operate
          in the digital age. Our journey began with the belief that technology,
          when harnessed effectively, can amplify human potential and transform
          brands.
        </p>
        <h2>Our Values:</h2>
        <p>
            At WeFrame, our core values are the compass guiding our journey. We
            are driven by:
          </p>
        <ul>
         
          <li>
            Excellence: We are committed to delivering the highest quality of
            service and exceeding the expectations of our clients.
          </li>
          <li>
            Innovation: We believe in staying at the forefront of
            technological advancements and consistently seeking creative
            solutions to complex challenges.
          </li>
          <li>
            Integrity: We uphold the principles of honesty, transparency, and
            ethical conduct in every interaction, fostering trust and respect.
          </li>
          <li>
            Client-Centric: Our clients are at the heart of everything we do.
            We actively listen to their needs, delivering solutions tailored to
            their unique objectives.
          </li>
          <li>
            Collaboration: We understand that the most significant
            achievements are a result of teamwork. We work collaboratively with
            our clients, partners, and our internal team to reach new heights
            together.
          </li>
          <li>
            Growth: We are committed to personal and professional growth, not
            just for our team but for every client we serve. We believe in
            empowering potential and fostering a culture of continuous
            improvement.
          </li>
          <p>
            These values shape our identity and drive us to excel, not just as a
            service provider but as a partner invested in your success.
          </p>
        </ul>
        <h2>Join Our Journey:</h2>
        <p>
          WeFrame invites you to join us on this exciting journey of growth and
          transformation. Discover how our expertise can help your business
          thrive and create a lasting impact in the digital era. Connect with us
          today to explore the endless possibilities of the future.
        </p>
        <h2>Empower Your Vision, Connect with WeFrame.</h2>
      </section>
      <VisionMission />
    </div>
  );
};

export default AboutUs;
