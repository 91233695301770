import { motion } from "framer-motion";
import React, { useState } from "react";

const TechnologyWeWorkOnNew = () => {
  const techData = [
    {
      icon:
        "https://w0.peakpx.com/wallpaper/214/158/HD-wallpaper-html5-logo-white-silk-texture-html5-emblem-programming-language-html-silk-background.jpg",
      title: "HTML5",
    },
    {
      icon:
        "https://www.robinheed.com/wp-content/uploads/2016/04/css3.jpg",
      title: "CSS3",
    },
    {
      icon: "https://wallpaperaccess.com/full/8085076.png",
      title: "ReactJS",
    },
    {
      icon: "https://wallpaperaccess.com/full/6953608.jpg",
      title: "MaterialUI",
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    if (index !== hoveredIndex) {
      setHoveredIndex(index);
    }
  };

  const handleHoverEnd = () => {
    setHoveredIndex(null);
  };

  const animationOptions = {
    y: [0, -50, 0],
    transition: { repeat: 5, duration: 0.5 },
  };

  return (
    <div className="tech-we-use">
      {techData.map((tech, index) => (
        <motion.div
          className={`bouncingDiv ${index === hoveredIndex ? "bounce" : ""}`}
          key={index}
          whileHover={hoveredIndex === null ? "bounce" : ""}
          onHoverStart={() => handleHover(index)}
          onHoverEnd={handleHoverEnd}
        >
          <motion.img
            src={tech.icon}
            alt={`Service Icon ${index + 1}`}
            className="tech-image"
            {...animationOptions}
          />
          <h4>{tech.title}</h4>
        </motion.div>
      ))}
    </div>
  );
};

export default TechnologyWeWorkOnNew;
