import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const WhatWeDo = () => {

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/./WhatWeDo.json`)
      .then((response) => response.json())
      .then((data) => setServices(data))
      .catch((error) => console.error("Error fetching what we do:", error));
  }, []);

  const [Services, setServices] = useState([]);
  const [flippedCards, setFlippedCards] = useState(
    new Array(Services.length).fill(false)
  );

  // Function to toggle the flip state of a card
  const toggleFlip = (index) => {
    const newFlippedCards = [...flippedCards];
    newFlippedCards[index] = !newFlippedCards[index];
    setFlippedCards(newFlippedCards);
  };

  return (
    <section className="what-we-do">
      <div>
        <h1>What We Do</h1>
      </div>
      <div className="row">
        {Services.map((service, index) => (
          <div
            className={`service ${flippedCards[index]}`}
            key={index}
            onClick={() => toggleFlip(index)}
          >
            <div className="what-we-do-card">
              <div className="what-we-do-card-front">
                <img src={service.icon} alt={`Service Icon ${index + 1}`} />
                <h4>{service.title}</h4>
              </div>
              <div
                className="what-we-do-card-back"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => window.open(`${service.path}`,'_blank')}
              >
                <h4>{service.title}</h4>
                <p>{service.shortDescription}</p>
                <Link
                  to={service.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhatWeDo;
