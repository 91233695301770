import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const SocialMediaIcon = ({ isSocialOpen }) => {
  return (
    <div className={`${!isSocialOpen ? "social-icons1" : "social-icons2"}`}>
      <ul className="social-links social-icons-list">
        <li className="social-icons-list-items">
          <a href="https://www.instagram.com/we__frame/" className="instagram" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </li>
        <li className="social-icons-list-items">
          <a href="#" className="linkedin">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialMediaIcon;
