
import React, { useState, useEffect } from 'react';
import './FAQ.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const FAQ = ({page}) => {
  const [faqs, setFaqs] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  console.log(page);

  const toggleFAQ = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/Faq.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => setFaqs(data))
      .catch((error) => console.error('Error fetching FAQ data:', error));
  }, []);
  
  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      <ul className="faq-list">
        {faqs.filter(faq => faq.page === page).map((faq, index) => (
          <li className="faq-list-items" key={index}>
            <button className="faq-question" onClick={() => toggleFAQ(index)}>
              <h2 className="faqQuestion">{faq.question}</h2>
              <FontAwesomeIcon icon={expandedIndex === index ? faAngleUp : faAngleDown} />
            </button>
            <div className={`faq-answer ${expandedIndex === index ? 'open' : ''}`}>
              {faq.answer}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;