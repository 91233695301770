// Vision.js
import React from "react";
import "../styles.css";

const VisionMission = () => {
  return (
    <div className="Vision-container">
      <div className="Vision-card">
        <img
          alt="vission"
          className="vision-img"
          src="./vision.webp"
        ></img>
        <h2>Our Vision</h2>
        <p>
          Our vision is to be a driving force in the digital transformation of
          businesses worldwide. We aspire to create a seamless bridge between
          technology and human excellence, making cutting-edge web development,
          efficient virtual assistant support, and strategic HR management
          accessible to all. We envision a future where businesses thrive,
          brands prosper, and people flourish in an interconnected world of
          possibilities.
        </p>
      </div>
      <div className="Vision-card">
        <img
          alt="mission"
          className="vision-img"
          src="https://i.pinimg.com/474x/86/b5/08/86b5088444c191ba142c200a782e57bc.jpg"
        ></img>
        <h2>Our Mission</h2>
        <p>
          Our mission is to empower businesses by providing
          top-notch virtual assistant, HR management, and web development
          services. We're committed to helping brands achieve their goals,
          enhance their online presence, and foster a thriving work culture.
          Through innovation and unwavering dedication, we aim to be the go-to
          partner for businesses looking to navigate the digital landscape with
          confidence
        </p>
      </div>
    </div>
  );
};

export default VisionMission;
