/* Inside the HRManagement.js component */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import FAQ from "./FAQ";
import HrServicesWeOffer from "./HrServicesWeOffer";
import ServicesOfferedHr from "./ServicesOfferedHr";

const HRManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const hrServices = [
    "Resume Screening",
    "Policy development",
    "Employee onboarding",
    "Performance Management",
    "Compliance management",
    "Employee Engagement",
    "Payroll Management",
    "Attendance management",
  ];
  return (
    <div className="Va-page">
      <header className="va-header">
        <h1>HR Management Services</h1>
        <p>Empowering Your Workforce</p>
      </header>
      <h2 style={{ marginBottom: 10, textAlign: "center", color:"#7ed957" }}>
        Services We Offer</h2>
      <HrServicesWeOffer />
      <div className="main-va-div">
        <div className="rightDiv">
          <h2>Service Details</h2>
          <ul className="serviceList">
            {hrServices.map((hrService) => (
              <li className="serviceListItems">
                <FontAwesomeIcon icon={faCheck} /> {hrService}
              </li>
            ))}
          </ul>
        </div>
        <div className="leftDiv">
          <div className="fixed-block"></div>
          <section className="introduction">
            <div className="intro-text">
              <h2>Welcome to Our HR Management Services</h2>
              <p>
                At WeFrame, we offer a comprehensive suite of HR services
                designed to streamline your human resources processes and
                elevate your workforce management. Explore the depth of our
                offerings below and discover how we can enhance the efficiency
                and effectiveness of your HR operations.
              </p>
            </div>
          </section>
          <section className="significanceVa">
            <h1>Why Choose Weframe for HR Services?</h1>
            <ul>
              <li>Employee-Centric Approach</li>
              <li>Efficiency and Accuracy</li>
              <li>Flexible and Tailored Solutions</li>
              <li>Confidentiality of your valuable document</li>
              <li>Consistent Communication</li>
            </ul>
          </section>
        </div>
      </div>
      <ServicesOfferedHr />
      <section className="contact">
        <p>Ready to empower your organization with our HR services?</p>
        <a href="/contact">Contact Us</a>
      </section>
      <FAQ page={"hrmanagement"} />
    </div>
  );
};

export default HRManagement;
