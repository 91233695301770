import React, { useEffect } from "react";
import "../styles.css";
// import { Link } from "react-scroll";
import WhatWeDo from "./WhatWeDo";
import WhyChoseUs from "./WhyChoseUs";
import AboutUs from "./AboutUs";
import FAQ from "./FAQ";
import Pricing from "./Pricing";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="homepage">
      <section className="hero">
        <div className="hero-bg" style={{ textAlign: "center" }}>
          <h1>WeFrame</h1>
          <h2>Your Trusted Partner</h2>
        </div>
      </section>
      <WhatWeDo />
      <AboutUs />
      <WhyChoseUs />
      <Pricing />
      <a href="/contact" className="homepage-contact">Free Consultation</a>
     <p style={{textAlign:"center", fontSize:"25px"}}>1 Week Trial Period Available    </p> 
     <p style={{textAlign:"center"}}>Starting at $200/Month</p>
   
      <FAQ page={"homepage"} />
    </div>
  );
};

export default HomePage;
