import React from "react";
import SocialMediaIcon from "./SocialMediaIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars, faEllipsisV,  } from "@fortawesome/free-solid-svg-icons";
import NavbarItems from "./NavbarItems.js";

const Header = ({
  isMenuOpen,
  toggleMenu,
  toggleSocial
}) => {
  return (
    <header className="logo global_header">
      <FontAwesomeIcon
        icon={isMenuOpen ? faArrowLeft : faBars}
        className="hamburger-menu"
        onClick={toggleMenu}
      />
        {/* <a href="/" style={{width:"40%"}}> */}
        <img src={`${process.env.PUBLIC_URL}/Logow.webp`} className="img-logo" alt="logo" />
        {/* </a> */}
      <NavbarItems />
      <FontAwesomeIcon
        icon={faEllipsisV}
        className="kebab-menu"
        onClick={toggleSocial}
      />
      <SocialMediaIcon />
    </header>
  );
};

export default Header;
