import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import VaServicesWeOffer from "./VaServicesWeOffer";
import FAQ from "./FAQ";
import ServicesOfferedVA from "./ServicesOfferedVa";

const VirtualAssistant = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const vaServices = [
    "Timely Meeting Reminder",
    "Travel Planning",
    "Organize, Classify, or Tag Your Files",
    "Formatting & Clean Up",
    "Flexible Working Hours",
    "Handling your Gsuite Account",
    "Graphic design",
    "Pocket Friendly VA",
  ];

  return (
    <div className="Va-page">
      <header className="va-header">
        <h1>Virtual Assistant Services</h1>
        <p>Elevate Your Brand with Our Virtual Assistants</p>
      </header>
      <h2 style={{ marginBottom: 10, textAlign: "center", color:"#7ed957" }}>
        Services We Offer
      </h2>
      <VaServicesWeOffer />
      <div className="main-va-div">
        <div className="rightDiv">
          <h2>Service Details</h2>
          <ul className="serviceList">
            {vaServices.map((vaService) => (
              <li className="serviceListItems">
                <FontAwesomeIcon icon={faCheck} /> {vaService}
              </li>
            ))}
          </ul>
        </div>
        <div className="leftDiv">
          <section className="introduction">
            <div className="intro-text">
              <h2>Welcome to Our Virtual Assistant Services</h2>
              <p>
                Welcome to WeFrame's Virtual Assistant Services, your partner in
                streamlining business operations and enhancing productivity. At
                WeFrame, we offer a comprehensive range of virtual assistant
                services designed to meet your specific business needs. With
                over two years of experience, we bring valuable expertise to
                your tasks, ensuring excellence, innovation, and integrity in
                every interaction.
              </p>
            </div>
          </section>
          <section className="significanceVa">
            <h1>Significance of Choosing us!</h1>
            <ul>
              <li>Confidentiality of your valuable document</li>
              <li>Quality assurance virtual assistant services</li>
              <li>Timely delivery</li>
              <li>Flexible and Tailored Solutions</li>
              <li>Consistent Communication</li>
            </ul>
          </section>
        </div>
      </div>
      <ServicesOfferedVA />
      <section className="contact">
        <p>
          Our goal is to become an integral part of your team, allowing you to
          focus on what you do best while we handle the rest.
        </p>
        <a href="/contact">Contact Us</a>
      </section>
      <FAQ page={"virtualassistant"} />
    </div>
  );
};

export default VirtualAssistant;
