// PageNotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="notfoundimage">
        <img src="/pagenotfound.jpg" alt="PageNotFound" />
        <button onClick={() => navigate("/")}>Go to HomePage</button>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default PageNotFound;
