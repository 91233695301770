import React from "react";
// import "./DropDownOptions.css";

export function DropDownOptions({
  isDropDownOpen,
  // handleDropDown,
  isMenuOpen,
  toggleMenu,
  handleMouseEnter,
  handleMouseLeave,
}) {
  const services = [
    { name: "Virtual Assistant", path: "/VirtualAssistant" },
    { name: "Web Development", path: "/WebsiteDevelopment" },
    { name: "HR Managment", path: "/HRManagement" },
  ];

  const handleChange = (path) => {
    isMenuOpen ? toggleMenu() : handleMouseLeave();
    window.open(path, "_blank");
    console.log(`${path}`);
  };

  return (
    <div
      className="servicesDropDown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ul className={isDropDownOpen ? "dropdown-menu" : "dropdown-menu-closed"}>
        {services.map((service) => {
          return (
            <li
              className="dropdown-menu-list-items"
              onClick={() => {
                handleChange(service.path);
              }}
            >
              {service.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
