import React, { useEffect, useState } from "react";
import "../styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faClock } from "@fortawesome/free-solid-svg-icons";
// import emailjs from "emailjs/browser";
import emailjs from "emailjs-com";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [serviceSelected, setServiceSelected] = useState([]);
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const validateForm = (form) => {
    const errors = {};

    if (!serviceSelected.length) {
      errors.service = "Please select at least one service";
    }

    // Validate name
    if (!form.name.value.trim()) {
      errors.name = "Name is required";
    } else if (!/^[a-zA-Z ]+$/.test(form.name.value)) {
      errors.name = "Name should only contain letters and spaces";
    }

    // Validate email
    if (!form.email.value.trim()) {
      errors.email = "Email is required";
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(form.email.value)
    ) {
      errors.email = "Enter a valid email address";
    }
    if (!form.phone.value.trim()) {
      errors.phone = "Phone is required";
    } else if (!/^[\d\s-()+]{10}$/.test(form.phone.value)) {
      errors.phone = "Enter a valid 10-digit phone number";
    }

    // Validate message
    if (!form.message.value.trim()) {
      errors.message = "Message is required";
    }else if (!/^[a-zA-Z ]+$/.test(form.name.value)) {
      errors.message = "Message should only contain letters and spaces";
    }


    setFormErrors(errors);

    // Return true if there are no errors, false otherwise
    return Object.values(errors).every((error) => !error);
  };

  const onSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    sendEmail(e.target); // Pass the form element to sendEmail
  };

  const sendEmail = async (form) => {
    if (!validateForm(form)) {
      return;
    }

    const services = serviceSelected.join(", ");

    try {
      const templateParams = {
        name: form.name.value,
        email: form.email.value,
        phone: form.phone.value,
        company: form.company.value,
        message: form.message.value + "\n\nInterested in services: " + services,
      };

      await emailjs.send(
        "service_q562ihd",
        "template_yuxd92b",
        templateParams,
        "x94hzPB3_pVjmLso7"
      );

      setIsFormSubmitted(true);

      // Reset the form after submission
      form.reset();
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <div className="homepage">
      <div className="contact-us-container">
        <h1>Contact Us</h1>
        <div className="card-container">
          <div className="card">
            <div className="icon">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="fa-icon email-icon"
              />
            </div>
            <div className="card-content">
              <p>contact@weframe.co.in</p>
              <a href="mailto:contact@weframe.co.in">Mail Us</a>
            </div>
          </div>
          <div className="card">
            <div className="icon">
              <FontAwesomeIcon icon={faClock} className="fa-icon clock-icon" />
            </div>
            <div className="card-content">
              <p>Monday to Saturday – 10:00 AM to 06:00 PM</p>
            </div>
          </div>
        </div>
        <div class="contact-form-container">
          {isFormSubmitted ? (
            <div className="success-message">
              <p>
                Thank you! Your form has been submitted successfully. We will
                shortly get in touch with you!
              </p>
            </div>
          ) : (
            <>
              <h2>Get in Touch!</h2>
              <p>
                Kindly fill out the form below and we will contact you soon.
              </p>
              <form onSubmit={onSubmit}>
                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" id="name" name="name" required />
                  {formErrors.name && (
                    <p className="error-message">{formErrors.name}</p>
                  )}
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="email" id="email" name="email" required />
                  {formErrors.email && (
                    <p className="error-message">{formErrors.email}</p>
                  )}
                </div>
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input type="text" id="phone" name="phone" required />
                  {formErrors.phone && (
                    <p className="error-message">{formErrors.phone}</p>
                  )}
                </div>
                <div class="form-group">
                  <label for="company">Company Name</label>
                  <input type="text" id="company" name="company" />
                  {formErrors.company && (
                    <p className="error-message">{formErrors.company}</p>
                  )}
                </div>
                <div class="form-group">
                  <label for="company">Service you are interested in:</label>
                  <input
                    type="checkbox"
                    id="va"
                    name="virtualAssistant"
                    value="Virtual Assistant"
                    onChange={(event) =>
                      setServiceSelected([
                        ...serviceSelected,
                        event.target.value,
                      ])
                    }
                  />{" "}
                  Virtual Assistant
                  <input
                    type="checkbox"
                    id="wd"
                    name="webDevelopment"
                    value="Web Development"
                    onChange={(event) =>
                      setServiceSelected([
                        ...serviceSelected,
                        event.target.value,
                      ])
                    }
                  />{" "}
                  Web Development
                  <input
                    type="checkbox"
                    id="hrm"
                    name="hrManagement"
                    value="HR Management"
                    onChange={(event) =>
                      setServiceSelected([
                        ...serviceSelected,
                        event.target.value,
                      ])
                    }
                  />{" "}
                  HR Management
                  {formErrors.service && (
                    <p className="error-message">{formErrors.service}</p>
                  )}
                </div>
                <div class="form-group">
                  <label for="message">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    required
                  ></textarea>
                  {formErrors.message && (
                    <p className="error-message">{formErrors.message}</p>
                  )}
                </div>
                <div class="form-group">
                  <button type="submit">SEND</button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
