import "./NavBarItems.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { DropDownOptions } from "./DropDownOptions";

function NavbarItems({ isMenuOpen, toggleMenu }) {
  const navigate = useNavigate();
  const [isDropDownOpen, setIsDropDownOpen] = useState(
    isMenuOpen ? true : false
  );

  const handleMouseEnter = () => {
    setIsDropDownOpen(true);
  };
  const handleMouseLeave = () => {
    setIsDropDownOpen(false);
  };

  return (
    <nav className={`${isMenuOpen ? "custom-nav-open" : "custom-nav"}`}>
      <ul className="navbar-menu-list" onClick={toggleMenu}>
        <li className="navbar-menu-list-items" onClick={() => navigate("/")}>
          Home
        </li>
        <li className="navbar-menu-list-items">
          <HashLink
            className="a-custom"
            to="/#aboutUs"
            spy={true}
            smooth={true}
            offset={-76}
            duration={750}
            activeClass="active"
          >
            About Us
          </HashLink>
        </li>
        <li className="dropdown ">
          <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            Services{" "}
            {isMenuOpen ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon
                icon={isDropDownOpen ? faCaretUp : faCaretDown}
              />
            )}
          </span>
          {isDropDownOpen ? (
            <DropDownOptions
              isMenuOpen={isMenuOpen}
              isDropDownOpen={isDropDownOpen}
              toggleMenu={toggleMenu}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              // handleDropDown={handleDropDown}
            />
          ) : (
            ""
          )}
        </li>
        <li
          className="navbar-menu-list-items"
          onClick={() => navigate("/contact")}
        >
          Contact Us
        </li>
      </ul>
    </nav>
  );
}

export default NavbarItems;
