// Footer.js
import React from "react";
import SocialMediaIcon from "./SocialMediaIcon";

const Footer = () => {
  return (
    <footer>
      <div className="footer-left" id="footer">
        <div className="logo logo-footer">WeFrame</div>
        <div className="reach-us">
          <h3>REACH US</h3>
          <p>contact@weframe.co.in</p>
        </div>
      </div>

      <div className="follow-us">
        <h3>
          Empowering Your Success in the Digital Era - WeFrame, Your Trusted
          Partner
        </h3>
        <p>©2023 WeFrame. All rights reserved.</p>
        <h3>FOLLOW US</h3>
        <div className="footer-social-icons">
          <SocialMediaIcon isSocialOpen={true} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
