import React, { useEffect, useState } from "react";

const ServicesOfferedWeb = () => {
  const [WebServices, setWebServices] = useState([]);

  useEffect(() => {
    fetch("./WebServices.json")
      .then((response) => response.json())
      .then((data) => setWebServices(data))
      .catch((error) => console.error("Error fetching Web services:", error));
  }, []);

  return (
    <section className="service-offerings">
      <div className="service-offerings-content">
        <h2>Our Website Development Services</h2>
        <p>
          At WeFrame, we offer a comprehensive range of Website Development
          services to streamline your business operations and enhance
          productivity. Our services include but are not limited to:
        </p>
        <ul>
          {WebServices.map((service, index) => (
            <li key={index}>
              <strong>{service.title}:</strong> {service.description}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ServicesOfferedWeb;
