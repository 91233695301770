/* Inside the WebsiteDevelopment.js component */
import React, { useEffect } from "react";
import TechnologyWeWorkOnNew from "./TechnologyWeWorkOnNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import FAQ from "./FAQ";
import WebServicesWeOffer from "./WebServicesWeOffer";
import ServicesOfferedWeb from "./ServicesOfferedWeb";

const WebsiteDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const techServices = [
    "10 Days Delivery",
    "2 Revisions",
    "Website Design and Development",
    "Responsive and User-Friendly Design",
    "Content Management System Integration",
    "Social Media Integration",
    "Cross-Browser Compatibility",
    "Interactive Features"
  ];
  return (
    <div className="Va-page">
      <header className="va-header">
        <h1>Website Development Services</h1>
        <p>Crafting Memorable Online Experiences</p>
      </header>
      <h2 style={{ marginBottom: 10, textAlign: "center", color:"#7ed957" }}>
        Services We Offer
      </h2>
      <WebServicesWeOffer />
      <div className="main-va-div">
        <div className="rightDiv">
          <h2>Service Details</h2>
          <ul className="serviceList">
            {techServices.map((techService) => (
              <li className="serviceListItems">
                <FontAwesomeIcon icon={faCheck} /> {techService}
              </li>
            ))}
          </ul>
        </div>
        <div className="leftDiv">
          <section className="introduction">
            <div className="intro-text">
              <h2>Welcome to Our Website Development Services</h2>
              <p>
                Welcome to WeFrame's Web Development Services, where innovation
                meets functionality. Our team of skilled developers is dedicated
                to bringing your digital vision to life using cutting-edge
                technologies like React.js. Whether you're looking to establish
                an online presence, upgrade your existing website, or dive into
                e-commerce, we're here to transform your ideas into a seamless
                and visually stunning reality.
              </p>
            </div>
          </section>

          <section className="significanceVa">
            <h1>Why Choose Us?</h1>
            <ul>
              <li>Expertise in Website Development</li>
              <li>Responsive and Mobile-Friendly Designs</li>
              <li>Custom Solutions</li>
              <li>Pocket Friendly</li>
              <li>Quality Checkup Services</li>
            </ul>
          </section>
        </div>
      </div>
      <ServicesOfferedWeb />
      <h1 style={{ textAlign: "center" }}>Technologies We Use</h1>
      <TechnologyWeWorkOnNew />
      <section className="contact">
        <p>
          Ready to take your online presence to the next level? Contact us today
          and let's get started on your web project.
        </p>
        <a href="/contact">Contact Us</a>
      </section>
      <FAQ page={"webdevelopment"} />
    </div>
  );
};

export default WebsiteDevelopment;
