import React, { useState, useEffect } from "react";

const ServicesOfferedVA = () => {
  const [vaServices, setVaServices] = useState([]);

  useEffect(() => {
    fetch("./VaServices.json")
      .then((response) => response.json())
      .then((data) => setVaServices(data))
      .catch((error) => console.error("Error fetching va services:", error));
  }, []);

  return (
    <section className="service-offerings">
      <div className="service-offerings-content">
        <h2>Our Virtual Assistant Services</h2>
        <p>
          At WeFrame, we offer a comprehensive range of Virtual Assistant
          services to streamline your business operations and enhance
          productivity. Our services include but are not limited to:
        </p>
        <ul>
          {vaServices.map((vaservice, index) => (
            <li key={index}>
              <strong>{vaservice.title}:</strong> {vaservice.description}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ServicesOfferedVA;
