import React, { useEffect, useState } from "react";

const ServicesOfferedHr = () => {
  const [HrServices, setHrServices] = useState([]);

  useEffect(() => {
    fetch("./HrServices.json")
      .then((response) => response.json())
      .then((data) => setHrServices(data))
      .catch((error) => console.error("Error fetching Hr services:", error));
  }, []);

  return (
    <section className="service-offerings">
      <div className="service-offerings-content">
        <h2>Our HR Services</h2>
        <p>
          At WeFrame, we offer a comprehensive range of Human Resources
          services to streamline your business operations and enhance
          productivity. Our services include but are not limited to:
        </p>
        <ul>
          {HrServices.map((service, index) => (
            <li key={index}>
              <strong>{service.title}:</strong> {service.description}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ServicesOfferedHr;