import React from "react";

const WhyChoseUs = () => {
  return (
    <section className="why-choose-us">
      {/* Updated "Why Choose Us?" section */}
      <h2>Why Choose Us?</h2>
      <div className="choose-us-content">
        <div className="choose-us-item">
          <img
            src="./Exp.webp"
            alt="Experience Icon"
          />
          <h3>Experience</h3>
          <p>
            With over two years of experience as a virtual assistant, we bring
            valuable expertise to your tasks.
          </p>
        </div>
        <div className="choose-us-item">
          <img
            src="./Rel.webp"
            alt="Reliability Icon"
          />
          <h3>Reliability</h3>
          {/* <h3>Introductory Rates and Packages</h3> */}
          <p>
            We are dedicated to delivering reliable and high-quality services to
            meet your business needs.
            {/* To help you experience the benefits of our Virtual Assistant
      services, we offer special introductory rates and packages. Get
      started today and discover how we can streamline your business
      operations efficiently. */}
          </p>
        </div>
        <div className="choose-us-item">
          <img
            src="./Cust.jpg"
            alt="Customization Icon"
          />
          <h3>Customization</h3>
          <p>
            We tailor our services to match your specific
            requirements and preferences.
          </p>
        </div>
      </div>
    </section>
  );
};
export default WhyChoseUs;
