import React, { useState, useEffect } from "react";

const Pricing = () => {
  const [services, setServices] = useState({});

  useEffect(() => {
    fetch("./WeFramePrice.json")
      .then((response) => response.json())
      .then((data) => {
        const organizedData = data.reduce((acc, service) => {
          if (!acc[service.service]) {
            acc[service.service] = [];
          }
          acc[service.service].push(service);
          return acc;
        }, {});
        setServices(organizedData);
      })
      .catch((error) => console.error("Error fetching services:", error));
  }, []);

  return (
    <div>
      <div className="price-container">
        {Object.keys(services).map((serviceType) => (
          <div
            className="Price-card"
            key={serviceType}
            id={`${
              Object.keys(services).indexOf(serviceType) === 1
                ? "blueTheme"
                : "whiteTheme"
            }`}
          >
            <div className="price-card-header">
              <h3>{serviceType}</h3>
              <p> ₹ ??? (+ 18% GST Applicable)</p>
            </div>
            <div className="services-bullets">
              <div className="features">
                {services[serviceType].map((service, index) => (
                  <div key={index}>
                    <h4>{service.feature}</h4>
                  </div>
                ))}
              </div>
              <a className="price-btn" href="/contact">Connect Now</a>
            </div>
          </div>
        ))}
      </div>
      {/* <div id="aboutUs1"></div> */}
    </div>
  );
};

export default Pricing;
